import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createReview, getProduct, getRelatedProducts } from "../../actions/productActions";
import Loader from "../layouts/Loader";
import MetaData from "../layouts/MetaData";
import { Modal } from 'react-bootstrap';
import { addCartItemToCart } from "../../actions/cartActions";
import { addCartItem, removeCartItem, updateCartItemQuantity } from "../../slices/cartSlice";
import { clearError, clearProduct, clearReviewSubmitted } from "../../slices/singleProductSlice";
import { toast } from "react-toastify";
import ProductReview from "./ProductReview";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './ProductDetail.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import Product from "./Product";
import { Link } from "react-router-dom";

export default function ProductDetail() {
    const { loading, product = {}, isReviewSubmitted, error } = useSelector((state) => state.productState);
    const { relatedProducts, loading: relatedLoading } = useSelector((state) => state.relatedProductsState);
    const { user } = useSelector((state) => state.authState);
    const dispatch = useDispatch();
    const { id } = useParams();
    const [quantity, setQuantity] = useState(1);
    const [mainMedia, setMainMedia] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [mainImage, setMainImage] = useState('');    // Set the first image as the default main image.

    useEffect(() => {
        if (product.images && product.images.length > 0) {
            setMainImage(product.images[0].image); // Set the first image as the default main image
        }
    }, [product.images]);
    // Related Product 
    useEffect(() => {
        if (!product._id || isReviewSubmitted) {
            dispatch(getProduct(id));
        }

        // Fetch related products when the main product is loaded
        if (product.maincategory) {
            dispatch(getRelatedProducts(product.maincategory));
        }
    }, [dispatch, id, isReviewSubmitted, product.maincategory]);
    const handleThumbnailClick = (image) => {
        setMainImage(image);
    };

    useEffect(() => {
        if (product.images && product.images.length > 0) {
            setMainMedia(product.images[0]);
        }
    }, [product.images]);

    const increaseQty = () => {
        if (product.stock === 0 || quantity >= product.stock) return; // Prevent increasing if stock is 0 or quantity is maxed out
        setQuantity(prevQuantity => prevQuantity + 1); // Update state to increase quantity
    };

    const decreaseQty = () => {
        if (quantity === 1) return; // Prevent decreasing below 1
        setQuantity(prevQuantity => prevQuantity - 1); // Update state to decrease quantity
    };


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [rating, setRating] = useState(1);
    const [comment, setComment] = useState("");

    const reviewHandler = () => {
        const formData = new FormData();
        formData.append("rating", rating);
        formData.append("comment", comment);
        formData.append("productId", id);
        dispatch(createReview(formData));
    };

    const handleAddToCart = () => {
        if (quantity > product.stock) {
            toast.error("Quantity exceeds available stock");
            return;
        }
        if (user) {
            dispatch(addCartItemToCart(product._id, quantity));
        } else {
            const cartItem = {
                product: product._id,
                name: product.name,
                price: product.price,
                image: product.images[0].url,
                stock: product.stock,
                quantity
            };
            dispatch(addCartItem(cartItem));
            toast.success("Item added to cart successfully");
        }
    };

    useEffect(() => {
        if (isReviewSubmitted) {
            handleClose();
            toast("Review Submitted successfully", {
                type: "success",
                onOpen: () => dispatch(clearReviewSubmitted())
            });
        }

        if (error) {
            toast(error, {
                type: "error",
                onOpen: () => dispatch(clearError())
            });
            return;
        }

        if (!product._id || isReviewSubmitted) {
            dispatch(getProduct(id));
        }
    }, [dispatch, id, isReviewSubmitted, error]);

    useEffect(() => {
        return () => {
            dispatch(clearProduct());
        };
    }, []);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Fragment>
            {loading ? <Loader /> :
                <Fragment>
                    <MetaData title={product.name} />

                    <div className="breadcrumbWrapper mb-4">
                        <div className="container-fluid">
                            <ul className="breadcrumb breadcrumb2 mb-0">
                                <li>
                                    <Link to={"/"}>Home</Link>
                                </li>
                                <li>Shop</li>
                                <li>{product.name}</li>
                            </ul>
                        </div>
                    </div>

                    <section className="Product-page-ist">
                        <div className="product d-flex">
                            {/* Product Image Gallery */}
                            <div className="column-xs-12 col-lg-4">
                                <div className="product-gallery">
                                    {/* Carousel for Product Images */}
                                    <div className="main-image-container">
                                        <img
                                            className="main-img"
                                            src={mainImage}
                                            alt={product.name}
                                        />
                                    </div>

                                    {/* Thumbnail List */}
                                    <ul className="image-list1">
                                        {product.images &&
                                            product.images.map((src, index) => (
                                                <li
                                                    key={index}
                                                    className={`image-item1 ${mainImage === src.image ? 'active-thumbnail' : ''}`}
                                                    onClick={() => handleThumbnailClick(src.image)} // Set main image on click
                                                >
                                                    <img
                                                        src={src.image} // Assuming `src.image` contains the image URL
                                                        alt={`Thumbnail ${index + 1}`}
                                                        className="thumbnail-img" // Optionally add styling class for thumbnail images
                                                    />
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="column-xs-12 col-lg-5 description">

                                <h2>{product.name}</h2>
                                <div className="description">
                                    <p>
                                        <span style={{ color: "skyblue", fontSize: "22px" }}>96%</span>
                                        <span style={{ color: "grey", fontStyle: "italic" }}>
                                            of respondents would recommend this to a friend
                                        </span>
                                    </p>
                                    <div>
                                        <p
                                            style={{
                                                fontWeight: "500",
                                                borderBottom: "2px solid #2f4d2a",
                                                width: "23%",
                                                paddingBottom: "7px"
                                            }}
                                            className="pt-3">
                                            Features & Benefits:
                                        </p>

                                        {/* Display Key Features Dynamically */}
                                        <ul
                                            style={{
                                                color: "#333333",
                                                listStyleType: "none",
                                            }}>
                                            {product.keyPoints && product.keyPoints.length > 0 ? (
                                                product.keyPoints.map((feature, index) => (
                                                    <li
                                                        key={index}
                                                        style={{
                                                            margin: "0 0 10px",
                                                            position: "relative",
                                                            paddingLeft: "25px"
                                                        }}>
                                                        <span
                                                            style={{
                                                                position: "absolute",
                                                                left: "0",
                                                                top: "50%",
                                                                transform: "translateY(-50%)",
                                                                width: "10px",
                                                                height: "10px",
                                                                borderRadius: "50%",
                                                                backgroundColor: "#2f4d2a",
                                                                display: "inline-block"
                                                            }}>
                                                        </span>
                                                        {feature} {/* Render each feature */}
                                                    </li>
                                                ))
                                            ) : (
                                                <li style={{ color: "red", fontStyle: "italic" }}>
                                                    No key features available.
                                                </li>
                                            )}
                                        </ul>
                                    </div>

                                    <div>
                                        {/* Accoridian */}
                                    </div>
                                </div>

                                {/* <button className="add-to-cart">Add To Cart</button> */}
                            </div>
                            <div className="column-xs-12 col-lg-3" style={{ boxShadow: "10px 10px 40px #eeeeee", top: "20px", height: "fit-content", padding: "2rem", borderRadius: "10px" }}>
                                <div className="">
                                    <p style={{ fontWeight: "bold" }}>Item #: {product.itemModelNum}</p>
                                </div>

                                <div className="">
                                    <span style={{ fontSize: "27px", fontWeight: "bold", color: "#2f4d2a" }}>INR {product.price}
                                    </span>
                                </div>

                                <div className="">
                                    <div className="d-flex items-center w-100">
                                        <div className="rating-outer d-flex align-items-center">
                                            <div className="rating-inner1" style={{ width: `${(product.ratings / 5) * 100}%` }}></div>
                                        </div>

                                        <div className="d-flex align-items-center ml-2">
                                            {user ? (
                                                <button onClick={handleShow} id="review_btn" type="button" className="btn" data-toggle="modal" data-target="#ratingModal">
                                                    <FontAwesomeIcon icon={faPen} />
                                                </button>
                                            ) : (
                                                <div className="" style={{ width: '50%' }}></div>
                                            )}
                                        </div>
                                    </div>

                                </div>

                                <div className="pt-2 pb-3">
                                    <div>
                                        <span style={{ fontWeight: "500", color: "#2f4d2a" }}>Stock :</span>
                                        <span style={{ color: "#2f4d2a" }}> {product.stock} </span><br />
                                        <button className="btn btn-outline-dark  m-2" onClick={decreaseQty}>-</button>
                                        <button className="btn btn-outline-dark  m-2" readOnly>{quantity}</button>
                                        <button className="btn btn-outline-dark  m-2" onClick={increaseQty}>+</button>
                                    </div>
                                </div>

                                <div className="pt-2 pb-2">
                                    <button className="btn btn-secondary text-white c2" disabled={product.stock === 0}
                                        onClick={handleAddToCart}>ADD TO CART</button>
                                </div>

                                <div className="pt-2 pb-2">
                                    <button className="btn btn-dark text-white c2">BUY NOW</button>
                                </div>

                                <div className="pt-2 ">
                                    <p style={{ fontStyle: "italic", fontSize: "15px", color: "#2f4d2a", fontWeight: "bold" }}>Fastest cross-border delivery</p>
                                </div>

                                <div className="pb-2">
                                    <span style={{ fontSize: "14px", color: "#2f4d2a", fontWeight: "bold" }}>Our Top Logistics Partners</span>
                                    <div className="mt-2">
                                        {/* <img src={partner1} className="img-fluid" />
                                        <img src={partner2} className="img-fluid ms-2" /> */}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Post Your valuable Reviews</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <ul className="stars">
                                    {[1, 2, 3, 4, 5].map(star => (
                                        <li
                                            value={star}
                                            key={star}
                                            onClick={() => setRating(star)}
                                            className={`star ${star <= rating ? 'orange' : ''}`}
                                            onMouseOver={(e) => e.target.classList.add('yellow')}
                                            onMouseOut={(e) => e.target.classList.remove('yellow')}
                                        >
                                            <i className="fa fa-star"></i>
                                        </li>
                                    ))}
                                </ul>

                                <textarea onChange={(e) => setComment(e.target.value)} name="review" id="review" className="form-control mt-3"></textarea>
                                <button disabled={loading} onClick={reviewHandler} aria-label="Close" className="my-3 float-right review-btn px-4 text-dark">Submit</button>
                            </Modal.Body>
                        </Modal>

                    </section>

                    <section className="container">
                        <h3 style={{ borderBottom: "3px solid #2f4d2a", width: "222px", paddingBottom: "10px", marginBottom: "30px" }}>Product Details</h3>
                        <div class="d-flex gap-3">
                            <div class="col-sm-6 mb-3 mb-sm-0">
                                <div class="card" style={{ height: "250px", border: "2px solid #2f4d2a" }}>
                                    <div class="card-body">
                                        <h5 class="card-title" style={{ borderLeft: "6px solid #222" }}>&nbsp; Specifications</h5>
                                        <p class="card-text mt-3">Brand name : <b>{product.brand}</b></p>
                                        <p class="card-text">Efficiency (%) : 0.75</p>
                                        <p class="card-text">Country of Origin : India</p>
                                        <p class="card-text">Lifting Weight  :Single Hook-750Kg, Double Hook-1500Kg</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="card" style={{ height: "250px", border: "2px solid #2f4d2a" }}>
                                    <div class="card-body">
                                        <h5 class="card-title" style={{ borderLeft: "6px solid #222" }}>&nbsp; Description</h5>
                                        <p class="card-text mt-3">{product.description}</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="container mt-3 mb-5">
                        <div class="d-flex gap-3">
                            <div className="col-sm-6 mb-3 mb-sm-0">
                                <div className="card" style={{ height: "450px", border: "2px solid #2f4d2a" }}>
                                    <div className="card-body">
                                        <h5 className="card-title" style={{ borderLeft: "6px solid #222" }}>&nbsp; Specifications</h5>
                                        <div
                                            style={{
                                                height: "50%", // Adjust height as needed
                                                overflowY: "auto",
                                                padding: "5px",
                                                borderRadius: "4px"
                                            }}>
                                            <table className="table">
                                                <tbody>
                                                    {product.brand && (
                                                        <tr>
                                                            <th>Brand</th>
                                                            <td>{product.brand}</td>
                                                        </tr>
                                                    )}
                                                    {product.itemModelNum && (
                                                        <tr>
                                                            <th>Item Model Number</th>
                                                            <td>{product.itemModelNum}</td>
                                                        </tr>
                                                    )}
                                                    {product.serialNum && (
                                                        <tr>
                                                            <th>Serial Number</th>
                                                            <td>{product.serialNum}</td>
                                                        </tr>
                                                    )}
                                                    {product.connectionType && (
                                                        <tr>
                                                            <th>Connection Type</th>
                                                            <td>{product.connectionType}</td>
                                                        </tr>
                                                    )}
                                                    {product.hardwarePlatform && (
                                                        <tr>
                                                            <th>Hardware Platform</th>
                                                            <td>{product.hardwarePlatform}</td>
                                                        </tr>
                                                    )}
                                                    {product.os && (
                                                        <tr>
                                                            <th>Operating System</th>
                                                            <td>{product.os}</td>
                                                        </tr>
                                                    )}
                                                    {product.powerConception && (
                                                        <tr>
                                                            <th>Power Conception</th>
                                                            <td>{product.powerConception}</td>
                                                        </tr>
                                                    )}
                                                    {product.batteries && (
                                                        <tr>
                                                            <th>Batteries</th>
                                                            <td>{product.batteries}</td>
                                                        </tr>
                                                    )}
                                                    {product.packageDimension && (
                                                        <tr>
                                                            <th>Package Dimension</th>
                                                            <td>{product.packageDimension}</td>
                                                        </tr>
                                                    )}
                                                    {product.portDescription && (
                                                        <tr>
                                                            <th>Port Description</th>
                                                            <td>{product.portDescription}</td>
                                                        </tr>
                                                    )}
                                                    {product.connectivityType && (
                                                        <tr>
                                                            <th>Connectivity Type</th>
                                                            <td>{product.connectivityType}</td>
                                                        </tr>
                                                    )}
                                                    {product.compatibleDevices && (
                                                        <tr>
                                                            <th>Compatible Devices</th>
                                                            <td>{product.compatibleDevices}</td>
                                                        </tr>
                                                    )}
                                                    {product.powerSource && (
                                                        <tr>
                                                            <th>Power Source</th>
                                                            <td>{product.powerSource}</td>
                                                        </tr>
                                                    )}
                                                    {product.specialFeatures && (
                                                        <tr>
                                                            <th>Special Features</th>
                                                            <td>{product.specialFeatures}</td>
                                                        </tr>
                                                    )}
                                                    {product.includedInThePackage && (
                                                        <tr>
                                                            <th>Included In The Package</th>
                                                            <td>{product.includedInThePackage}</td>
                                                        </tr>
                                                    )}
                                                    {product.manufacturer && (
                                                        <tr>
                                                            <th>Manufacturer</th>
                                                            <td>{product.manufacturer}</td>
                                                        </tr>
                                                    )}
                                                    {product.itemSize && (
                                                        <tr>
                                                            <th>Item Size</th>
                                                            <td>{product.itemSize}</td>
                                                        </tr>
                                                    )}
                                                    {product.itemWidth && (
                                                        <tr>
                                                            <th>Item Width</th>
                                                            <td>{product.itemWidth}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="card" style={{ height: "450px", border: "2px solid #2f4d2a" }}>
                                    <div class="card-body">
                                        <h5 class="card-title" style={{ borderLeft: "6px solid #222" }}>&nbsp; Reviews</h5>
                                        <div
                                            style={{
                                                height: "80%", // Adjust height as needed
                                                overflowY: "auto",
                                                padding: "5px",
                                                borderRadius: "4px"
                                            }}>
                                            <p class="card-text mt-3">{product.reviews && product.reviews.length > 0 && <ProductReview reviews={product.reviews} />}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* original */}


                    <section className='homeProducts homeProductsRow2 pt-0'>
                        <div className='container-fluid'>
                            <div className='d-flex align-items-center'>
                                <h2 className='hd mb-0 mt-0'>Featured Products</h2>
                            </div>

                            <div className='row '>
                                <div className='col-12'>
                                    {relatedLoading ? (
                                        <Loader />
                                    ) : (
                                        <Swiper
                                            modules={[Navigation, Pagination, Scrollbar]}
                                            navigation
                                            spaceBetween={20}
                                            className="productRow-related"
                                            breakpoints={{
                                                1500: {
                                                    slidesPerView: 5, // 4 slides for screens >= 1200px
                                                },
                                                1200: {
                                                    slidesPerView: 4, // 4 slides for screens >= 1200px
                                                },
                                                1000: {
                                                    slidesPerView: 3, // 3 slides for screens >= 1000px and < 1200px
                                                },
                                                768: {
                                                    slidesPerView: 2, // 2 slides for screens >= 768px and < 1000px
                                                },
                                                0: {
                                                    slidesPerView: 1, // 1 slide for screens < 768px
                                                }
                                            }}
                                        >
                                            {relatedProducts.map((relatedProduct) => (
                                                <SwiperSlide key={relatedProduct._id} className="productCard">
                                                    <Product product={relatedProduct} />
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>

                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                </Fragment>
            }
        </Fragment>
    );
}




import React, { useEffect, useContext } from "react";
import "../layouts/nav.css";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import GridViewIcon from "@mui/icons-material/GridView";
import HeadphonesOutlinedIcon from "@mui/icons-material/HeadphonesOutlined";
import Logo from "../../images/procure-g-logo.png";
import HomeIcon from '@mui/icons-material/Home';
import BuildIcon from '@mui/icons-material/Build'; // For Power Tools
import WaterIcon from '@mui/icons-material/Water'; // For Pumps & Motors
import SchoolIcon from '@mui/icons-material/School'; // For Office Stationery
import MedicalServicesIcon from '@mui/icons-material/MedicalServices'; // For Medical Supplies

export default function Nav() {
  return (
    <>
    <div className="nav d-flex align-items-center " >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-sm-2 part1 d-flex align-items-center">
              <nav>
                <li className="list-inline-item sec-nav-pro">  <Button className="bg-g text-white catTab res-hide"><GridViewIcon /> &nbsp;Browse All Categories <KeyboardArrowDownIcon /></Button>
                  <div className="dropdown_menu">
                    <ul className="mb-0">
                      <li>
                        <HomeIcon className="icon-sec-nav" />  Home
                      </li>
                    </ul>
                  </div>
                </li>
              </nav>
            </div>


            <div className="col-sm-8 part2 position-static">
              <nav>
                <ul className="list list-inline mb-0">
                  <li className="list-inline-item sec-nav-pro">  Home
                  </li>

                  <li className="list-inline-item sec-nav-pro">  Electricals<KeyboardArrowDownIcon className="rotateIcon" />
                    <div className="dropdown_menu">
                      <ul className="mb-0">
                        <li>
                          <HomeIcon className="icon-sec-nav" />  Home
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li className="list-inline-item sec-nav-pro"> Power Tools
                  </li>
                  <li className="list-inline-item sec-nav-pro"> Pumps & Motors
                  </li>
                  <li className="list-inline-item sec-nav-pro">  Office Stationery & Supplies
                  </li>
                  <li className="list-inline-item sec-nav-pro">   Medical Supplies

                  </li>
                </ul>
              </nav>
            </div>

            <div className="col-sm-2 part3 d-flex align-items-center">
              <div className="phNo d-flex align-items-center ml-auto">
                <span>
                  <HeadphonesOutlinedIcon />
                </span>
                <div className="info ml-3">
                  <h3 className="text-g mb-0">1900 - 888</h3>
                  <p className="mb-0">24/7 Support Center</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


import { Fragment, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { countries } from 'countries-list';
import { saveShippingInfo, saveBillingInfo } from "../../slices/cartSlice.js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CheckoutSteps from "./CheckoutSteps.js";
import MetaData from '../layouts/MetaData';
import { createSavedAddress, getAllSavedAddresses } from "../../actions/userActions.js";
import 'bootstrap/dist/js/bootstrap.js';
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";

const fetchPostalCodeDetails = async (postalCode) => {
  try {
    const response = await fetch(`https://api.postalpincode.in/pincode/${postalCode}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching pin code details:", error);
    throw error;
  }
};

const isValidPhoneNumber = (phoneNo) => {
  const phoneNumberRegex = /^\d{10,13}$/;
  return phoneNumberRegex.test(phoneNo);
};

const isValidPostalCode = (postalCode) => {
  const postalCodeRegex = /^\d{6}$/;
  return postalCodeRegex.test(postalCode);
};

export const validateShipping = (shippingInfo, navigate) => {
  const { address, addressLine, city, state, country, phoneNo, postalCode } = shippingInfo;

  if (
    !address || !addressLine || !city || !state || !country || !phoneNo || !postalCode ||
    !isValidPhoneNumber(phoneNo) || !isValidPostalCode(postalCode)
  ) {
    toast.error('Please fill the shipping information correctly');
    navigate('/shipping');
  }
};

export default function Shipping() {
  const { shippingInfo = {}, billingInfo = {} } = useSelector(state => state.cartState);
  const { savedAddresses = [] } = useSelector(state => state.userState);
  const [address, setAddress] = useState(shippingInfo.address || "");
  const [addressLine, setAddressLine] = useState(shippingInfo.addressLine || "");
  const [city, setCity] = useState(shippingInfo.city || "");
  const [phoneNo, setPhoneNo] = useState(shippingInfo.phoneNo || "");
  const [country, setCountry] = useState(shippingInfo.country || "");
  const [state, setState] = useState(shippingInfo.state || "");
  const [postalCode, setPostalCode] = useState(shippingInfo.postalCode || "");
  const [localities, setLocalities] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [showSavedAddresses, setShowSavedAddresses] = useState(false);
  const [billingSameAsShipping, setBillingSameAsShipping] = useState(true);
  const [billingAddress, setBillingAddress] = useState(billingInfo.address || "");
  const [billingAddressLine, setBillingAddressLine] = useState(billingInfo.addressLine || "");
  const [billingCity, setBillingCity] = useState(billingInfo.city || "");
  const [billingPhoneNo, setBillingPhoneNo] = useState(billingInfo.phoneNo || "");
  const [billingCountry, setBillingCountry] = useState(billingInfo.country || "");
  const [billingState, setBillingState] = useState(billingInfo.state || "");
  const [billingPostalCode, setBillingPostalCode] = useState(billingInfo.postalCode || "");
  const [billingLocalities, setBillingLocalities] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const countryList = Object.values(countries);
  useEffect(() => {
    dispatch(getAllSavedAddresses());
  }, [dispatch]);

  const handlePincodeChange = useCallback(async (e, setPostalCode, setLocalities, setCity, setState, setCountry) => {
    const postalCode = e.target.value;
    setPostalCode(postalCode);
    if (postalCode.length === 6) {
      try {
        const data = await fetchPostalCodeDetails(postalCode);
        if (data && data[0]?.PostOffice?.length > 0) {
          const offices = data[0].PostOffice;
          setLocalities(offices.map(office => office.Name));
          setCity(offices[0].Name);
          setState(offices[0].State);
          setCountry(offices[0].Country);
        } else {
          toast.error("Invalid pin code");
        }
      } catch (error) {
        console.error("Error fetching pin code details:", error);
        toast.error("Error fetching pin code details");
      }
    }
  }, []);

  const handleAddressSelect = useCallback((selectedAddressId) => {
    setSelectedAddress(selectedAddressId);
    const selected = savedAddresses.find(addr => addr._id === selectedAddressId);
    if (selected) {
      setAddress(selected.address);
      setAddressLine(selected.addressLine);
      setCity(selected.city);
      setPhoneNo(selected.phoneNo);
      setPostalCode(selected.postalCode);
      setCountry(selected.country);
      setState(selected.state);
    }
  }, [savedAddresses]);

  const submitHandler = useCallback(async (e) => {
    e.preventDefault();
    if (!isValidPhoneNumber(phoneNo) || !isValidPostalCode(postalCode)) {
      toast.error('Please fill the shipping information correctly');
      return;
    }
    if (!billingSameAsShipping) {
      if (!isValidPhoneNumber(billingPhoneNo) || !isValidPostalCode(billingPostalCode)) {
        toast.error('Please fill the billing information correctly');
        return;
      }
    }

    dispatch(saveShippingInfo({ address, addressLine, city, phoneNo, postalCode, country, state }));

    if (billingSameAsShipping) {
      dispatch(saveBillingInfo({ address, addressLine, city, phoneNo, postalCode, country, state }));
    } else {
      dispatch(saveBillingInfo({ address: billingAddress, addressLine: billingAddressLine, city: billingCity, phoneNo: billingPhoneNo, postalCode: billingPostalCode, country: billingCountry, state: billingState }));
    }

    try {
      await dispatch(createSavedAddress({ address, addressLine, city, phoneNo, postalCode, country, state }));
      navigate('/order/confirm');
    } catch (error) {
      console.error("Error saving address in user model:", error);
    }
  }, [address, addressLine, billingAddress, billingAddressLine, billingCity, billingCountry, billingPhoneNo, billingPostalCode, billingState, billingSameAsShipping, city, country, dispatch, navigate, phoneNo, postalCode, state]);

  return (
    <>
      <section className="section pt-5 pb-4" >
        <div className="container">

          <form className="checkoutForm" onSubmit={submitHandler} noValidate>
            <div className="row">
              <div className="col-md-8">
                <h2 className="hd">SHIPPING ADDRESS</h2>

                {/* Form Contents */}
                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                  {savedAddresses && savedAddresses.length > 0 && (
                    <div>
                      <div>
                        {savedAddresses.map(address => (
                          <div key={address._id} className="p-3 col-lg-12">
                            <input
                              type="radio"
                              id={address._id}
                              name="savedAddress"
                              value={address._id}
                              checked={selectedAddress === address._id}
                              onChange={() => handleAddressSelect(address._id)}
                            />
                            <label htmlFor={address._id}>{`${address.address},`} {address.addressLine},</label> <br />
                            <label htmlFor={address._id}>{`${address.city},`}</label> <br />
                            <label htmlFor={address._id}>{`${address.country} - ${address.postalCode},`}</label> <br />
                            <label htmlFor={address._id}>{`${address.phoneNo}`}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>



                <div className="row mt-3">
                  <div className="col-md-6">

                    {/* phone no */}
                    <div className="form-group">
                      <TextField
                        label="Phone Number"
                        variant="outlined"
                        className="w-100"
                        size="small"
                        type="number"
                        value={phoneNo}
                        onChange={(e) => setPhoneNo(e.target.value)}
                        required
                        placeholder="Eg:9874653210"
                        maxLength={10}

                      />
                    </div>
                  </div>

                  <div className="col-md-6">

                    {/* pincode */}
                    <div className="form-group">
                      <TextField
                        label="PIN Code"
                        variant="outlined"
                        className="w-100"
                        size="small"
                        name="pincode"
                        placeholder="Pincode"
                        value={postalCode}
                        onChange={(e) => handlePincodeChange(e, setPostalCode, setLocalities, setCity, setState, setCountry)}
                        required
                        maxLength={6}
                      />
                    </div>
                  </div>
                </div>

                <h6>Street address *</h6>

                <div className="row">
                  <div className="col-md-12">

                    {/* address */}
                    <div className="form-group">
                      <TextField
                        label="House number and street name"
                        variant="outlined"
                        className="w-100"
                        size="small"
                        name="streetAddressLine1"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        required
                      />
                    </div>

                    {/* <div className="form-group">
                               <TextField
                                   label="Apartment, suite, unit, etc. (optional)"
                                   variant="outlined"
                                   className="w-100"
                                   size="small"
                                   name="streetAddressLine2"

                               />
                           </div> */}
                  </div>
                </div>

                <h6>Locality / Town *</h6>

                <div className="row">
                  <div className="col-md-12">

                    {/* Locality / Town */}
                    <div className="form-group">

                      <select
                        id="addressLine_field"
                        className="form-control"
                        value={addressLine}
                        onChange={(e) => setAddressLine(e.target.value)}
                        required
                      >
                        <option value="">Select Locality</option>
                        {localities.map((locality, index) => (
                          <option key={index} value={locality}>{locality}</option>
                        ))}
                      </select>

                    </div>
                  </div>
                </div>

                <h6>City *</h6>

                <div className="row">
                  <div className="col-md-12">

                    {/* City */}
                    <div className="form-group">
                      <TextField
                        label="City"
                        variant="outlined"
                        className="w-100"
                        size="small"
                        name="City"
                        value={city}
                        readOnly
                        required
                      />
                    </div>
                  </div>
                </div>

                <h6>State *</h6>

                <div className="row">
                  <div className="col-md-12">

                    {/* State */}
                    <div className="form-group">
                      <TextField
                        label="State"
                        variant="outlined"
                        className="w-100"
                        size="small"
                        name="state"
                        value={state}
                        readOnly
                        required
                      />
                    </div>
                  </div>
                </div>

                <h6>Country *</h6>

                <div className="row">
                  <div className="col-md-12">

                    {/* Country */}
                    <div className="form-group">
                      <select
                        id="country_field"
                        className="form-control"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        required
                      >
                        {countryList.map((country, i) => (
                          <option key={i} value={country.name}>
                            {country.name}
                          </option>
                        ))}
                      </select>

                    </div>
                  </div>
                </div>

              </div>

              <div className="col-md-4 cartRightBox ">
                <div className="card orderInfo">
                  <h4 className="hd">YOUR ORDER</h4>
                  <div className="table-responsive mt-3">
                    <table className="table table-borderless">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Subtotal</th>
                        </tr>
                      </thead>

                      <tbody>


                        <tr>
                          <td>Subtotal </td>

                          <td>

                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <Button type="submit" className="btn-g btn-lg w-100">
                    Checkout
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section >
    </>
  );
}

import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ProductModal from './ProductModal';
import { addCartItemToCart } from '../../actions/cartActions';
import { addCartItem } from '../../slices/cartSlice';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import "./ProductDetail.css";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";

export default function Product({ product }) {
    const [showModal, setShowModal] = useState(false);
    const { user } = useSelector(state => state.authState);
    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState(1);

    const handleModalClose = () => setShowModal(false);
    const handleModalShow = (event) => {
        event.stopPropagation();
        setShowModal(true);
    };

    const handleAddToCart = (event) => {
        event.stopPropagation(); // Prevents the event from bubbling up to the card's onClick handler
        if (quantity > product.stock) {
            toast.error('Quantity exceeds available stock');
            return;
        }
        if (user) {
            dispatch(addCartItemToCart(product._id, quantity));
        } else {
            const cartItem = {
                product: product._id,
                name: product.name,
                price: product.price,
                image: product.images[0].image,
                stock: product.stock,
                quantity
            };
            dispatch(addCartItem(cartItem));
            toast.success('Item added to cart successfully');
        }
    };

    const openProductDetailPage = () => {
        window.open(`/products/${product._id}`, '_blank');
    };

    return (
        <>
            <div className="productGrid">
                <div className=" mb-4">
                    <div className=" pb-0 pt-2"  >
                        <div className="productThumb" onClick={openProductDetailPage}>
                            <div className="imgWrapper">
                                <div className="wrapper mb-3">
                                    <img src={product.images[0].image} className="w-100" alt={product.name} />
                                </div>
                                <div className="overlay transition"></div>
                                <ul className="actions list-inline mb-0">
                                    <li className="list-inline-item">
                                        <a className="cursor" onClick={(e) => e.stopPropagation()}>
                                            <FavoriteBorderOutlinedIcon />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a onClick={handleModalShow} >VIEW MORE</a>
                                    </li>
                                </ul>
                            </div>

                            <div className="info">
                                <span className="d-block catName"><b>{product.category}</b></span>
                                <h6 className="title mb-3"><b>{product.name}</b></h6>
                                <span className="brand d-block text-g">
                                    {/* By <Link className="text-g" onClick={(e) => e.stopPropagation()}>{product._id}</Link> */}
                                    By <Link className="text-g" >{product.brand}</Link>
                                </span>
                                <div className="d-flex align-items-center mt-3 mb-2">
                                    <div className="d-flex align-items-center w-100">
                                        <span className="price text-g font-weight-bold">
                                            ₹{product.offPrice}.00/-
                                        </span>
                                        <span className="oldPrice ml-2">
                                            ₹{product.price}.00/-
                                        </span>
                                    </div>
                                </div>
                                <Button
                                    type="button"
                                    id="cart_btn1"
                                    className="btn bg-success"
                                    disabled={product.stock === 0}
                                    onClick={handleAddToCart}
                                >
                                    Add to Cart
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ProductModal show={showModal} handleClose={handleModalClose} product={product} />
        </>
    );
}

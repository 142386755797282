import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../layouts/wishlist.css";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Rating from "@mui/material/Rating";
import { Button } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import emprtCart from "../../images/wishlist.png";
import HomeIcon from "@mui/icons-material/Home";

export default function Wishlist() {

  const [isLoading, setIsLoading] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }
  )
  return (
    <>

      <div className="breadcrumbWrapper mb-4">
        <div className="container-fluid">
          <ul className="breadcrumb breadcrumb2 mb-0">
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>My List</li>
          </ul>
        </div>
      </div>


      <section className="cartSection mb-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="myListWrapper">
                <div className="d-flex align-items-center w-100">
                  <div className="left">
                    <h1 className="hd mb-0">My List</h1>
                    <p>
                      There are{" "}
                      <span className="text-g">
                        <b></b>
                      </span>{" "}
                      products in your Wishlist
                    </p>
                  </div>
                </div>


                <div className="cartWrapper mt-4">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Price</th>
                          <th>Remove</th>
                        </tr>
                      </thead>

                      <tbody>


                        <tr >
                          <td width={"70%"}>
                            <div className="d-flex align-items-center">
                              <div className="img">
                                {/* <Link
                                  to={`/product/${item.productId}`}
                                >
                                  <img
                                    src={item?.image}
                                    className="w-100"
                                  />
                                </Link> */}
                              </div>

                              <div className="info pl-4">
    
                                  <h4>
                                    {/* {item?.productTitle?.substr(
                                      0,
                                      50
                                    ) + "..."} */}
                                  </h4>
                              </div>
                            </div>
                          </td>

                          <td width="20%">
                            <span>Rs:</span>
                          </td>

                          <td width="10%">
                            <span
                              className="cursor"
                            >
                              <DeleteOutlineOutlinedIcon />
                            </span>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>



                <div className="empty d-flex align-items-center justify-content-center flex-column">
                  <img
                    src={emprtCart}
                    alt="image"
                    width="100"

                  />
                  <br />
                  <h3>Your Wishlist is currently empty</h3>
                  <br />
                  <Link to="/">
                    <Button className="btn-g bg-g btn-lg btn-big btn-round">
                      <HomeIcon /> &nbsp; Continue Shopping
                    </Button>
                  </Link>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};



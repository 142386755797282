import React from "react";
import './ourbus.css'
import ourbusineeg1 from "../OURBUSINESS/OURBUSINESS--IMAGES/OURBUSINESSG1.png"
import ourbusineeg2 from "../OURBUSINESS/OURBUSINESS--IMAGES/OURBUSINESSG2.png"
import ourbusineeg3 from "../OURBUSINESS/OURBUSINESS--IMAGES/OURBUSINESSG3.png"
import ourbusineeg4 from "../OURBUSINESS/OURBUSINESS--IMAGES/OURBUSINESSG4.png"
import Nav from '../nav';
export default function Ourbusiness() {
    return (
        <>

        <Nav/>

            <body className="container-fluid">
                {/* EMPOWERING GLOBAL TRADE */}
                <section className="empowering-global-section-procureg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 empowering-global-left-procureg">
                                <img src={ourbusineeg1} className="img-fluid w-100" />
                            </div>
                            <div className="col-lg-6 empowering-global-right-procureg">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">Empowering Global Trade: Simplifying Industrial Transactions with ProcureG</h5>
                                        <p class="card-text">ProcureG is a global e-commerce platform that connects buyers and sellers, simplifying transactions with fast shipping, reliable support, and opportunities for consumers to expand their reach and ensure seamless, high-quality experiences.</p>
                                        <button className="mt-3">Get started</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* OUR BUSINESS AT PROCUREG */}
                <section className="our-business-section-procureg">
                    <div className="container">
                        <div className="our-business-contents-procureg">
                            <h3>OUR BUSINESS AT PROCUREG</h3>
                            <p>ProcureG is a leading global e-commerce platform that simplifies the purchase of industrial <br /> products, offering fast shipping and dedicated customer support for a seamless experience.<br /> In addition to serving businesses, we also provide consumers with the opportunity to sell their <br /> products, expanding market reach and driving growth. By connecting the global industrial <br /> marketplace, ProcureG empowers vendors and consumers alike, ensuring access to high-quality <br /> products and facilitating business expansion with reliability and ease.</p>
                        </div>
                    </div>

                    {/* GLOBAL BUYER */}
                    <div className="global-buyer-contents-procureg">
                        {/* <div className="global-buyer-back-contents-procureg">
                        <img src={ourbusineeg2} className="img-fluid w-100" />
                    </div> */}
                        <div class="card ">
                            <div>
                                <img class="card-img" src={ourbusineeg2} alt="Card image" />
                            </div>

                            <div class="card-img-overlay global-buyer-full-contents-procureg container">
                                <div className="row">
                                    <div className="col-lg-6 mb-5 global-buyer-left-contents-procureg">
                                        <h3>ProcureG connects global buyers and sellers with seamless,efficient
                                            e-commerce solutions.</h3>
                                    </div>
                                    <div className="col-lg-6 global-buyer-right-contents-procureg">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <h5 class="card-title">Strategy</h5>
                                                        <p class="card-text">Our strategy is to provide a seamless, efficient
                                                            e-commerce platform that connects global buyers and sellers, offering fast shipping, reliable customer support, and growth opportunities for both businesses and consumers.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <h5 class="card-title">Goal</h5>
                                                        <p class="card-text">Our goal is to empower users in the global industrial marketplace by simplifying product transactions, expanding market reach, and delivering high-quality products through a reliable and user-friendly platform.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-lg-6">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <h5 class="card-title">What We Do</h5>
                                                        <p class="card-text">We facilitate global transactions by offering an e-commerce platform where buyers can easily purchase industrial products, and consumers and businesses can sell their products, growing their reach and market presence.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <h5 class="card-title">Why We Do It</h5>
                                                        <p class="card-text">We aim to streamline the industrial marketplace by providing innovative solutions that drive business growth, enhance customer satisfaction, and foster a global community of buyers and sellers..</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                {/* OUR CORE VALUES */}
                <section className="our-core-section-procureg">
                    <div className="container">

                        <div className="our-core-heading-procureg">
                            <h3>OUR CORE VALUES</h3>
                        </div>

                        <div className="our-core-sub-section-procureg">
                            <h3>Customer-Centricity</h3>
                            <p>We focus on delivering exceptional customer experiences through fast shipping, reliable support, and <br /> seamless transactions. Our goal is to consistently meet and exceed customer expectations, building<br /> long-lasting relationships.</p>
                        </div>

                        <div className="our-core-sub-section-procureg">
                            <h3>Innovation</h3>
                            <p>We continuously improve our platform using the latest technology to simplify the buying and selling <br /> process. By staying ahead of market trends, we ensure our users have access to efficient, <br /> state-of-the-art solutions.</p>
                        </div>

                        <div className="our-core-sub-section-procureg">
                            <h3>Global Connectivity</h3>
                            <p>We bridge the gap between buyers and sellers worldwide, enabling businesses to expand their reach and <br /> engage in cross-border trade. Our platform promotes global commerce and <br /> fosters international growth opportunities.</p>
                        </div>

                        <div className="our-core-sub-section-procureg">
                            <h3>Reliability</h3>
                            <p>We are committed to providing dependable service with consistent, high-quality experiences. <br /> Our focus on trust and reliability ensures customers can count on us for <br /> timely support and smooth transactions.</p>
                        </div>

                    </div>
                </section>

                {/* EMPOWER ACCELERATE */}
                <section className="empower-accelerate-section-procureg">
                    <div className="container empower-accelerate-heading-procureg">
                        <h3>Together, we connect, empower, and accelerate your <br /> business on a global scale</h3>
                        <p>We collaborate to expand your reach, enhance growth, and drive success in <br /> the global marketplace.</p>
                    </div>

                    <div className="container empower-accelerate-4card-procureg">
                        <div className="row">
                            <div className="col-lg-4">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">01</h5>
                                        <h6 class="card-subtitle mb-2">Sign Up & Product Listing</h6>
                                        <p class="card-text">Create an account and list your industrial products or explore available options on our platform.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4" style={{ marginTop: "10%" }}>
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">02</h5>
                                        <h6 class="card-subtitle mb-2">Order Placement</h6>
                                        <p class="card-text">Buyers place secure orders, and sellers efficiently process them with fast shipping and reliable service.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4" style={{ marginTop: "20%" }}>
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">03</h5>
                                        <h6 class="card-subtitle mb-2">Customer Support</h6>
                                        <p class="card-text">Our dedicated support team ensures smooth transactions, and feedback helps improve the overall experience for all users.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                {/* KEY BENEFITS */}
                <section className="key-benefits-section-procureg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 key-benefits-left-procureg">
                                <h3>Key Benefits of ProcureG: Empowering Global Trade <br /> and Growth</h3>
                                <p>ProcureG provides businesses and consumers with global reach, seamless transactions, and growth opportunities on an efficient,<br /> reliable platform for industrial success.</p>
                            </div>
                            <div className="col-lg-7 ">
                                <div className="key-benefits-right-procureg">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title">Global Reach:</h5>
                                            <p class="card-text">ProcureG connects buyers and sellers globally, breaking down geographical barriers for seamless cross-border trade. This opens up access to a larger, international marketplace.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="key-benefits-right-procureg mt-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title">Seamless Transactions:</h5>
                                            <p class="card-text">Our platform simplifies the purchasing and selling process, ensuring fast shipping and dependable customer support for smooth transactions. We make every step easy and efficient.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="key-benefits-right-procureg mt-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title">Business Growth Opportunities:</h5>
                                            <p class="card-text">We provide businesses and consumers with the tools and resources needed to expand their reach, optimize sales, and thrive in the global marketplace.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* BUYING & SELLING */}
                <section className="buying-selling-section-procureg">
                    <div class="card text-white">
                        <img class="card-img" src={ourbusineeg3} alt="Card image" />
                        <div class="card-img-overlay container">

                            <div className="buying-selling-contents-procureg">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="">
                                            <h5 class="card-title">01</h5>
                                            <h6 class="card-title">Buying on ProcureG</h6>
                                            <p class="card-text">ProcureG offers buyers easy access to a wide variety of <br /> industrial products from global sellers. With a user-friendly platform, <br /> secure transactions, fast shipping, and reliable customer support, we ensure <br /> a seamless and efficient purchasing experience.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 text-end">
                                        <div className="">
                                            <h5 class="card-title">02</h5>
                                            <h6 class="card-title">Selling on ProcureG</h6>
                                            <p class="card-text">For sellers, ProcureG provides an opportunity to reach a global marketplace, expand their business, and increase sales. Our platform offers tools and resources to help sellers manage listings, process orders, and deliver products efficiently, driving growth and success.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>

                {/* GET STARTED PROCUREMENT */}
                <section className="started-procurement-section-procureg">
                    <div className="row">
                        <div className="col-lg-2 started-procurement-content1-procureg">
                            <img src={ourbusineeg4} alt="Card image" />
                        </div>
                        <div className="col-lg-8 started-procurement-content2-procureg">
                            <h3>Let's Get Started with Your Procurement!</h3>
                            <p>Join ProcureG today to streamline your procurement, access global suppliers, and effortlessly buy or sell quality industrial products for business growth.</p>
                        </div>
                        <div className="col-lg-2 started-procurement-content3-procureg">
                            <button>Contact us</button>
                        </div>
                    </div>
                </section>
            </body>

        </>
    )
}
import React from 'react';
import animationData from './Animation - 1729334216439.json';
import Lottie from 'lottie-react';

export default function Loader() {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',  // Full viewport height
            width: '100vw'    // Full viewport width
        }}>
            <Lottie 
                animationData={animationData} 
                loop={true} 
                style={{ width: '300px', height: '300px' }} // Optional: set animation size
            />
        </div>
    );
}   

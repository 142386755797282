import React, { useContext, useEffect, useState } from "react";
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MetaData from '../layouts/MetaData';
import './user.css'
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { IoMdCloudUpload } from "react-icons/io";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import NoUserImg from "../../images/noimage.jpg";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Nav from '../layouts/nav';

export default function Profile() {
    const { user } = useSelector(state => state.authState);

    return (
        <>
            <MyAccount />
            <Fragment>
                <MetaData title={`Profile`} />
                <div className="container mt-5">
                    <div className="row ">
                        <div className=" col-lg-5 mb-4 ">
                            <center >
                                <figure className='avatar avatar-profile'>
                                    <img className="rounded-circle img-fluid" src={user.avatar ?? './images/default_avatar.png'} alt='' />
                                </figure>
                                <div>
                                    <Link to="/myprofile/update" id="edit_profile" className="btn btn-primary col-lg-6 btn-block my-3 ">
                                        Edit Profile
                                    </Link>
                                </div>

                            </center>
                        </div>
                        <div className=" col-lg-7 mt-4">
                            <h4>Full Name</h4>
                            <p>{user.name}</p>
                            <h4>Email Address</h4>
                            <p>{user.email}</p>
                            <h4>Joined</h4>
                            <p>{String(user.createdAt).substring(0, 10)}</p>
                            <div className='row mt-4'>
                                <div className='use-link mt-3 '>
                                    <Link to="/orders" className="btn btn-danger col-lg-3  me-2 mb-3">
                                        My Orders
                                    </Link>
                                    <Link to="/myprofile/update/password" id="edit_profile" className="btn btn-primary col-lg-3   me-2 mb-3">
                                        Change Password
                                    </Link>
                                    <Link to="/myprofile/saved-address" id="edit_profile" className="btn col-lg-3  btn-primary mb-3">
                                        Saved Address
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        </>
    )
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const MyAccount = () => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const history = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [uploading, setUploading] = useState(false);

    const [previews, setPreviews] = useState([]);
    const [userData, setUserData] = useState([]);

    const formdata = new FormData();

    const [formFields, setFormFields] = useState({
        name: "",
        email: "",
        phone: "",
        images: [],
        isAdmin: false,
        password: "",
    });

    const [fields, setFields] = useState({
        oldPassword: "",
        password: "",
        confirmPassword: "",
    });

    const changeInput = (e) => {
        setFormFields(() => ({
            ...formFields,
            [e.target.name]: e.target.value,
        }));
    };

    const changeInput2 = (e) => {
        setFields(() => ({
            ...fields,
            [e.target.name]: e.target.value,
        }));
    };

    return (
        <>
        <Nav/>
            <section className="section myAccountPage">
                <div className="container">
                    <h2 className="hd">My Account</h2>

                    <Box sx={{ width: "100%" }} className="myAccBox card border-0">
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                            >
                                <Tab label="Edit Profile" {...a11yProps(0)} />
                                <Tab label="MY ADDRESS" {...a11yProps(1)} />
                                <Tab label="Change Password" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <form >
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="userImage d-flex align-items-center justify-content-center">
                                            {uploading === true ? (
                                                <CircularProgress />
                                            ) : (
                                                <>
                                                    {previews?.length !== 0 ? (
                                                        previews?.map((img, index) => {
                                                            return <img src={img} key={index} />;
                                                        })
                                                    ) : (
                                                        <img src={NoUserImg} />
                                                    )}
                                                    <div className="overlay d-flex align-items-center justify-content-center">
                                                        <IoMdCloudUpload />
                                                        <input
                                                            type="file"
                                                            multiple
                                                            name="images"
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <TextField
                                                        label="Name"
                                                        variant="outlined"
                                                        className="w-100"
                                                        name="name"
                                                        onChange={changeInput}
                                                        value={formFields.name}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <TextField
                                                        label="Email"
                                                        disabled
                                                        variant="outlined"
                                                        className="w-100"
                                                        name="email"
                                                        onChange={changeInput}
                                                        value={formFields.email}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <TextField
                                                        label="Phone"
                                                        variant="outlined"
                                                        className="w-100"
                                                        name="phone"
                                                        onChange={changeInput}
                                                        value={formFields.phone}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <Button
                                                type="submit"
                                                className="btn-g btn-lg btn-big"
                                            >
                                                {isLoading === true ? <CircularProgress /> : "Save"}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <div className=" cartRightBox" style={{ width: "fit-content" }}>
                                <div className="card p-4">
                                    <h5 className="mb-0 text-light">4/66 t <br />
                                        Honnathalai village billicombai post <br />
                                        Kattabettu <br />
                                        The Nilgiris <br />
                                        643214 <br />
                                        1235468790</h5>
                                    <br />
                                    <div>
                                        <Button ><RemoveIcon /></Button>
                                        <Button ><AddIcon /></Button>
                                    </div>
                                </div>
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            <form >
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <TextField
                                                        label="Old Password"
                                                        variant="outlined"
                                                        className="w-100"
                                                        name="oldPassword"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <TextField
                                                        label="New password"
                                                        variant="outlined"
                                                        className="w-100"
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="form-group">
                                            <Button type="submit" className="btn-g btn-lg btn-big" id="new_password_button" >
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </CustomTabPanel>
                    </Box>
                </div>
            </section>
        </>
    );
};